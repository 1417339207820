@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: auto;
  height: calc(100vh - 145px);

  @media (--viewportSmall) {
    /* height: 454px; */
    height: calc(100vh - 368px);
    min-height: 350px;
  }

  @media screen and (min-width: 720px) and (min-width: 720px) {
    height: calc(100vh - 780px);
  }

  @media (--viewportMedium) {
    height: calc(100vh - 360px);
  }

  @media (--viewportLarge) {
    height: calc(100vh - 235px);
  }

  @media (--viewportMLarge) {
    min-height: 480px;
  }

  @media (--viewportLargeForHero) {
    height: calc(100vh - 300px);
    min-height: 450px;
  }
}

.hero {
  flex-grow: 1;
  overflow: hidden;
  /*justify-content: flex-end;*/
  /*@media (--viewportMedium) {*/
  /*  padding-bottom: 80px;*/
  /*}*/
  @media (--viewportLarge) {
    /*padding-bottom: 80px;*/
    justify-content: flex-start;
  }
}

.sections {
  margin: 0;
  padding-top: 68px;

  @media (--viewportSmall) {
    padding-top: 1px;
  }
}

.section {
  overflow: auto;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1000px;
    padding: 0 40px 0 40px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 36px;
}

.blueBg {
  background-color: var(--marketplaceColorDark) !important;
}

.hideOnMobile {
  display: none;
  @media (--viewportMedium) {
    display: list-item;
  }
}

/* A bar on top of light sections */
/* .section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px; */

  /* Place the bar on top of .sectionContent top margin */
  /* position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
} */
